import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CancelIcon from "@mui/icons-material/Cancel";
import TimeIcon from "@mui/icons-material/AccessTime";
import ClearIcon from "@mui/icons-material/Clear";
import cloneDeep from "lodash/cloneDeep";
import InputSearch from "./InputSearch";
import InputText from "./InputText";
import InputPassword from "./InputPassword";
import InputTextArea from "./InputTextArea";
import InputToggle from "./InputToggle";
import InputFile from "./InputFile";
import InputMobile from "./InputMobile";
import InputNumber from "./InputNumber";
import { renderNA } from "../../../utilities/helper";
import MiniLoader from "../MiniLoader";
import InputMonthYear from "./InputMonthYear";
import InputDate from "./InputDate";
import MyTooltip from "../Tooltip";
import InputTime from "./InputTime";
import ConditionWrapper from "./ConditionWrapper";
import InputRadio from "./InputRadio";

export const INPUT_TYPE = {
    TEXTAREA: 0x1,
    TEXT: 0x2,
    NUMBER: 0x3,
    UPLOAD: 0x4,
    SEARCH: 0x5,
    PASSWORD: 0x6,
    TOGGLE: 0x7,
    CHECKBOX: 0x8,
    MOBILE: 0x9,
    EMAIL: 0x10,
    DATE: 0x11,
    MONTH_YEAR: 0x12,
    TIME: 0x13,
    RADIO: 0x14
};

const { TEXTAREA, TEXT, NUMBER, UPLOAD, SEARCH, TOGGLE, CHECKBOX, PASSWORD, MOBILE, EMAIL, DATE, MONTH_YEAR, TIME, RADIO } = INPUT_TYPE;
const INPUT_WITH_INTERNAL_LABEL = [UPLOAD, TEXTAREA];
const INPUT_WITH_INTERNAL_ICON = [UPLOAD, MOBILE];

function Input({
    icon,
    isLarge,
    label,
    renderValue,
    readOnly,
    parentStyle,
    afterExtra,
    wrapperStyle,
    haslabel,
    hidelabel,
    type = TEXT,
    nativeType,
    isLoading,
    noBorder,
    tooltip,
    outlined,
    hide,
    isClearable,
    useSimpleClearIcon,
    subtext,
    afterLabel,
    warning,
    error,
    useSubTextStyle,
    errorProps,
    warningProps,
    ...rest
}) {
    const [isMounted, setMounted] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isUpload = type === UPLOAD;
    const isTextarea = type === TEXTAREA;
    const isMobile = type === MOBILE;
    const isSearch = type === SEARCH;
    const isDate = type === DATE;
    const isTime = type === TIME;
    const isDisabled = rest.disabled;
    const showOnUnmounted = [TEXT, DATE, NUMBER, UPLOAD];
    const hasCondition = !!warning?.length || !!error?.length;

    const commonInputProps = {
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
        readOnly,
        ...rest
    };

    if (isUpload) {
        delete commonInputProps.onFocus;
        delete commonInputProps.onBlur;
    }

    if (nativeType) {
        commonInputProps.type = nativeType;
    }

    useEffect(() => {
        setMounted(true);
    }, []);

    const createInput = (config = {}) => {
        const pstyle = { ...(parentStyle || {}) };
        const noLabel = label && config.hidelabel;
        const enableClearable = (rest.value || rest.selected) && isClearable;

        if (isUpload || isTextarea) {
            pstyle.borderBottom = "none";
            pstyle.marginBottom = ".5rem";
        }
        if (config.hidelabel) {
            pstyle.minWidth = "unset";
        }
        if (!hasCondition && noBorder) {
            pstyle.border = "none";
        }
        if (isDate) {
            pstyle.overflow = "unset";
            config.afterIcon = <CalendarMonthIcon />;
        }
        if (isTime) {
            pstyle.overflow = "unset";
            config.afterIcon = <TimeIcon className="time-icon" />;
        }

        const createClassNames = () => {
            let classnames = "tk-input ";

            if (config.className) classnames += config.className + " ";
            if (isLarge) classnames += "lg ";
            if (isSearch) classnames += "search ";
            if (outlined) classnames += "outlined ";
            if (isDate) classnames += "date ";
            if (isTime) classnames += "time ";
            if (enableClearable) classnames += "clearable ";

            return classnames.trim();
        };

        const handleClear = () => {
            rest.onChange?.("", "changing", "cleared");
        };

        return (
            <div className={createClassNames()} style={pstyle}>
                {!isMobile && config.icon && !noLabel && <div className="tk-input__icon-before">{config.icon}</div>}
                {isLoading ? (
                    <div className="has-loader">
                        <MiniLoader styles={isSearch ? { loader: { color: "white" } } : {}} show />
                    </div>
                ) : (
                    <>
                        {readOnly ? (
                            <div className="tk-input__inner"> {(renderValue && <span className="semi-bold">{renderValue}</span>) || renderNA()}</div>
                        ) : (
                            config.component
                        )}
                        {!isMobile && (readOnly ? renderValue : true) && config.afterIcon ? (
                            <div className="tk-input__icon-after">{config.afterIcon}</div>
                        ) : (
                            ""
                        )}
                    </>
                )}
                {enableClearable &&
                    (useSimpleClearIcon ? (
                        <ClearIcon
                            className={isDisabled ? "disabled" : "hover-svg fade"}
                            style={{
                                width: "1.3rem",
                                marginTop: "1px",
                                opacity: "0.7"
                            }}
                            onClick={() => !isDisabled && handleClear()}
                        />
                    ) : (
                        <CancelIcon
                            className={isDisabled ? "disabled" : "hover-svg fade"}
                            style={{ width: "1.3rem" }}
                            onClick={() => !isDisabled && handleClear()}
                        />
                    ))}
            </div>
        );
    };

    const createConfig = () => {
        let newClassName = "";
        let newIcon = icon;
        let newOptions = cloneDeep(rest);
        let afterIcon = afterExtra;
        let component = "";
        let nolabel = hidelabel;

        if (isDisabled) {
            newClassName += " is-disabled";
        }

        if (label || haslabel) {
            newClassName += " has-label";
            if (label && !nolabel) {
                newIcon = (
                    <div className="tk-input__label flex gap-05 center">
                        <span style={{ whiteSpace: "nowrap" }}>
                            {label}
                            {rest.required && !readOnly && !rest.disabled ? <span className="danger-color bold">*</span> : ""}
                        </span>
                        {tooltip && (
                            <MyTooltip message={tooltip} className="flex">
                                <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                            </MyTooltip>
                        )}
                        {afterLabel}
                    </div>
                );
            }
        }

        if (INPUT_WITH_INTERNAL_LABEL.includes(type)) {
            nolabel = true;
            commonInputProps.label = label;
        }

        if (INPUT_WITH_INTERNAL_ICON.includes(type)) {
            if (newIcon) {
                commonInputProps.icon = newIcon;
                newIcon = "";
            }
        }

        if (isFocused) {
            newClassName += " is-focused";
        }

        if (nolabel) {
            if (!newOptions?.style) {
                newOptions.style = {};
            }
            newOptions.style.textAlign = "center";
            newOptions.style.paddingRight = "8px";
        }

        switch (type) {
            default:
            case TEXT: {
                component = <InputText {...commonInputProps} />;
                break;
            }
            case TEXTAREA: {
                component = <InputTextArea {...commonInputProps} tooltip={tooltip} />;
                break;
            }
            case NUMBER: {
                newClassName += " is-number";
                component = <InputNumber {...commonInputProps} />;
                break;
            }
            case UPLOAD: {
                component = <InputFile {...commonInputProps} hidelabel={hidelabel} />;
                break;
            }
            case SEARCH: {
                newIcon = <SearchIcon />;
                component = <InputSearch {...commonInputProps} />;
                break;
            }
            case TOGGLE: {
                newClassName += " is-checkbox is-toggle";
                component = <InputToggle {...commonInputProps} />;
                break;
            }
            case CHECKBOX: {
                newClassName += " is-checkbox";
                component = <InputToggle {...commonInputProps} />;
                break;
            }
            case PASSWORD: {
                afterIcon = showPass ? (
                    <VisibilityOffIcon onClick={() => setShowPass(false)} style={{ marginLeft: ".4rem" }} />
                ) : (
                    <VisibilityIcon onClick={() => setShowPass(true)} style={{ marginLeft: ".4rem" }} />
                );
                component = <InputPassword {...commonInputProps} showPass={showPass} />;
                break;
            }
            case MOBILE: {
                component = <InputMobile {...commonInputProps} />;
                break;
            }
            case EMAIL: {
                component = <InputText {...commonInputProps} type="email" />;
                break;
            }
            case DATE: {
                component = <InputDate {...commonInputProps} />;
                break;
            }
            case MONTH_YEAR: {
                component = <InputMonthYear {...commonInputProps} />;
                break;
            }
            case TIME: {
                component = <InputTime {...commonInputProps} isLoading={isLoading} />;
                break;
            }
            case RADIO: {
                newClassName += " is-radio";
                component = <InputRadio {...commonInputProps} />;
                break;
            }
        }

        return { icon: newIcon, afterIcon, options: newOptions, className: newClassName.trim(), component, hidelabel: nolabel };
    };

    if (hide || (!isMounted && !readOnly && !showOnUnmounted.includes(type))) {
        return <></>;
    }

    return (
        <ConditionWrapper
            warnings={warning}
            errors={error}
            subtext={subtext}
            useSubTextStyle={useSubTextStyle}
            errorProps={errorProps}
            warningProps={warningProps}
            style={wrapperStyle || {}}
        >
            {createInput(createConfig())}
        </ConditionWrapper>
    );
}

Input.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isLarge: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    isSearch: PropTypes.object,
    renderValue: PropTypes.any,
    readOnly: PropTypes.bool,
    parentStyle: PropTypes.object,
    afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    haslabel: PropTypes.bool,
    hidelabel: PropTypes.bool,
    hide: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
    nativeType: PropTypes.string,
    isLoading: PropTypes.bool,
    noBorder: PropTypes.bool,
    tooltip: PropTypes.any,
    outlined: PropTypes.bool,
    isClearable: PropTypes.bool,
    afterLabel: PropTypes.any,
    useSimpleClearIcon: PropTypes.bool,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    }),
    warning: PropTypes.array,
    error: PropTypes.array,
    errorProps: PropTypes.object,
    warningProps: PropTypes.object,
    wrapperStyle: PropTypes.object,
    useSubTextStyle: PropTypes.bool
};

export default Input;
