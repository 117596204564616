import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { DATE_RANGE, FORM_TYPE } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import DateFilter from "../../../common/components/extra/filter/DateFilter";
import { FILTER_TYPE, FORM_APPROVAL_TYPE, FORM_STATUS } from "./const";
import { defaultFilter, resetFilter, selectFilter, setFilter } from "./slice";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";
import { createUserLevelOptions } from "./UserLevelSelect";

const COMMON_PROPS = {
    style: { minWidth: "10rem", height: "2rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.TYPE]: {
            options: toFilterOptions(FORM_TYPE).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Form Type" : opt.label)}</span>
            }))
        },
        [FILTER_TYPE.APPROVAL_TYPE]: {
            options: toFilterOptions(FORM_APPROVAL_TYPE).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Approval Type" : opt.label)}</span>
            }))
        },
        [FILTER_TYPE.MIN_USER_LEVEL]: {
            options: createUserLevelOptions({ small: true, bold: true, isFilter: true })
        },
        [FILTER_TYPE.STATUS]: {
            options: toFilterOptions(FORM_STATUS).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Status" : opt.label)}</span>
            }))
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleCustomDateChange, handleFilter }] =
        useFilterManager({
            onFilter,
            filterConfig,
            defaultFilter,
            filter: recordFilter,
            isLoading,
            setFilter,
            resetFilter,
            exclude: [FILTER_TYPE.CATEGORY]
        });
    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.TYPE].options}
                    value={getValueFromOpt(FILTER_TYPE.TYPE, filter[FILTER_TYPE.TYPE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.TYPE, conf.value)}
                    placeholder="Type"
                    isClearable={!!filter[FILTER_TYPE.TYPE]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.APPROVAL_TYPE].options}
                    value={getValueFromOpt(FILTER_TYPE.APPROVAL_TYPE, filter[FILTER_TYPE.APPROVAL_TYPE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.APPROVAL_TYPE, conf.value)}
                    placeholder="Approval Type"
                    isClearable={!!filter[FILTER_TYPE.APPROVAL_TYPE]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.MIN_USER_LEVEL].options}
                    value={getValueFromOpt(FILTER_TYPE.MIN_USER_LEVEL, filter[FILTER_TYPE.MIN_USER_LEVEL])}
                    onChange={(conf) => handleChange(FILTER_TYPE.MIN_USER_LEVEL, conf.value)}
                    placeholder="Min User Level"
                    isClearable={
                        (typeof filter[FILTER_TYPE.MIN_USER_LEVEL] == "number" && filter[FILTER_TYPE.MIN_USER_LEVEL] == 0) ||
                        !!filter[FILTER_TYPE.MIN_USER_LEVEL]
                    }
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.STATUS].options}
                    value={getValueFromOpt(FILTER_TYPE.STATUS, filter[FILTER_TYPE.STATUS])}
                    onChange={(conf) => handleChange(FILTER_TYPE.STATUS, conf.value)}
                    placeholder="Status"
                    isClearable={!!filter[FILTER_TYPE.STATUS]}
                    isDisabled={isLoading}
                />

                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.DATE),
                        value: filter[FILTER_TYPE.DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        }
                    }}
                    label="Created"
                />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool
};
