import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, setCurrent, setState, selectLoading, setLoading, selectCompanyFormsData, defaultConfig } from "./slice";
import {
    useCreateCompanyFormsMutation,
    useUpdateCompanyFormsMutation,
    useDeleteCompanyFormsMutation,
    useGetCompanyFormsMutation,
    useLoadAllCompanyFormsMutation,
    useGetActiveFieldsMutation,
    useValidateCompanyFormsMutation
} from "./api";
import { FIELD_OBJECT, FILTER_TYPE, FORM_CATEGORY, USER_LEVEL } from "./const";
import { FIELD } from "./const";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { NAME, TYPE, FIELDS, STATUS, CATEGORY, MIN_USER_LEVEL, APPROVAL_TYPE, APPROVAL_LEVELS } = FIELD;

export const useGetCompanyForm = (id, callback, useCache = true) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);

    const [getDetails] = useGetCompanyFormsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const createVars = (data) => {
        if (!data) {
            return {};
        }
        return {};
    };

    const fetch = async ({ isForce } = {}) => {
        if (!id) {
            return;
        }
        try {
            if (!isForce && useCache && current && current.id === id) {
                fetching && setFetching(false);
                return current;
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch data. Please try again later");
            }
            const data = result.data.data;
            dispatch(setCurrent(data));
            callback?.(data);
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyForm = ({ readOnly, isHR, isEMP } = {}) => {
    const getCategory = (config) => (config.isHR ? FORM_CATEGORY.HR : config.isEMP ? FORM_CATEGORY.EMPLOYEE : "");

    const [load, isLoading, { onFilter, onSearch, onSort, data, tableConfig, resetTableConfig, onUpdate }] = usePaginateFetch(
        useLoadAllCompanyFormsMutation,
        {
            redux: {
                dataSelector: selectCompanyFormsData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: { filter: { [FILTER_TYPE.CATEGORY]: getCategory({ isHR, isEMP }) } },
            runOnMount: !readOnly
        }
    );

    const fetch = async (config) => {
        try {
            const body = cloneDeep(config || {});
            const newFilter = {
                ...body.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(body)
            };
            delete body.isHR;
            delete body.isEMP;
            const response = await load({
                ...body,
                filter: newFilter
            });
            if (response?.error) {
                throw new Error("Failed to fetch company forms. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const handleSearch = (value, extra = {}) =>
        onSearch(value, {
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    const handleFilter = (newFilter, extra = {}) =>
        onFilter(
            {
                ...tableConfig.filter,
                ...newFilter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            },
            extra
        );

    const handleSort = (sort = {}, extra = {}) =>
        onSort(sort, {
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    const handleResetTable = (extra = {}) =>
        resetTableConfig({
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    return [
        data,
        {
            isLoading,
            tableConfig,
            fetch,
            update: onUpdate,
            onFilter: handleFilter,
            onSearch: handleSearch,
            onSort: handleSort,
            resetTableConfig: handleResetTable
        }
    ];
};

export const useUpsertCompanyForm = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [NAME]: FIELD_OBJECT[NAME].default,
        [TYPE]: FIELD_OBJECT[TYPE].default,
        [FIELDS]: FIELD_OBJECT[FIELDS].default,
        [CATEGORY]: FIELD_OBJECT[CATEGORY].default,
        [MIN_USER_LEVEL]: FIELD_OBJECT[MIN_USER_LEVEL].default,
        [APPROVAL_TYPE]: FIELD_OBJECT[APPROVAL_TYPE].default,
        [APPROVAL_LEVELS]: FIELD_OBJECT[APPROVAL_LEVELS].default,
        [STATUS]: FIELD_OBJECT[STATUS].default
    });

    const [data, { isLoading: isGettingForm }] = useGetCompanyForm(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyFormsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyFormsMutation();

    const createVars = () => {
        return {
            isLoading: isGettingForm || createIsLoading || updateIsLoading
        };
    };

    const upsert = async (newForm) => {
        let result = null;
        try {
            const clonedform = cloneDeep(newForm);

            clonedform.fields = clonedform.fields.map((field) => {
                delete field.data.sortable;
                return field;
            });

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Company Form ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Company Form. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        if (!isCreate && !isGettingForm) {
            const temp = {
                [NAME]: data?.[NAME],
                [TYPE]: data?.[TYPE],
                [FIELDS]: data?.[FIELDS],
                [CATEGORY]: data?.[CATEGORY],
                [MIN_USER_LEVEL]: data?.[MIN_USER_LEVEL] || USER_LEVEL.ZERO,
                [APPROVAL_TYPE]: data?.[APPROVAL_TYPE],
                [APPROVAL_LEVELS]: data?.[APPROVAL_LEVELS] || [],
                [STATUS]: data?.[STATUS]
            };
            setForm(temp);
            setOld(temp);
        }
    }, [data, updateId, isGettingForm]);

    const vars = createVars();

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingForm,
            isUpserting: createIsLoading || updateIsLoading,
            isLoading: vars.isLoading,
            config: vars,
            old,
            hasChangesToBaseForm: !!(old && !isCreate && !isEqual(JSON.stringify(form), JSON.stringify(old)))
        }
    ];
};

export const useDeleteCompanyForm = () => {
    const dispatch = useAppDispatch();

    const [deleteCompanyForm] = useDeleteCompanyFormsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteCompanyForm({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete company form.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useGetActiveFields = () => {
    const [fetching, setFetching] = useState(true);
    const [load] = useGetActiveFieldsMutation();
    const [fields, setFields] = useState([]);
    const [searchFields, setSearchFields] = useState([]);

    const fetch = async () => {
        if (!fetching) {
            setFetching(true);
        }
        try {
            const response = await load();
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to load fields.");
            }
            const data = response.data.data;
            setFields(data);
            setSearchFields(data);
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!fields.length) {
            fetch();
        }
    }, []);

    const fieldsToGroup = () => {
        return searchFields.reduce(
            (prev, current) => ({
                ...prev,
                [current.group]: [...(prev?.[current.group] || [])].concat(current)
            }),
            {}
        );
    };

    const onSearch = (value) => {
        setSearchFields(fields.filter((field) => field.name.includes(value.toLowerCase().trim())));
    };

    return [fieldsToGroup(), fetching, { refetch: fetch, onSearch, original: fields }];
};

export const useValidateCompanyForms = () => {
    const [validating, setValidating] = useState(true);
    const [isValid, setValid] = useState(false);

    const [validateForm] = useValidateCompanyFormsMutation();

    const params = useParams();
    const updateId = params.id;
    const location = useLocation();
    const current = useAppSelector(selectCurrent);
    const isUpdateRoute = location.pathname.includes("/update");

    const checkValidity = async () => {
        if (!updateId || !!current || !isUpdateRoute) {
            setValidating(false);
            if (current) {
                setValid(true);
            }
            return;
        }
        if (!validating) {
            setValidating(true);
        }
        try {
            const response = await validateForm({ extraPath: updateId });
            const data = response.data.data;
            if (response.error) {
                setValid(false);
                return false;
            }
            setValid(data.isValid);
            return data.isValid;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setValidating(false);
        }
    };

    useEffect(() => {
        checkValidity();
    }, []);

    return [isUpdateRoute ? isValid : true, validating];
};
