import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { createRecordStatus } from "../../employeeWorkHistories/helper";
import ShiftStatus from "../../employeeWorkHistories/ShiftStatus";
import { useAppSelector } from "../../../../common/hooks/reduxHooks";
import { selectUser } from "../../../common/slice";
import {
    createMiniTootlip,
    createTextFromDate,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toTimeWithTimeZone
} from "../../../../common/utilities/helper";
import { SHIFT_TYPE, WORK_HISTORY_TYPE } from "../../../../common/utilities/const";
import LetteredAvatar from "../../../../common/components/extra/avatar/LetteredAvatar";
import Tag from "../../../../common/components/extra/Tag";
import OvertimeStatus from "../../employeeWorkHistories/OvertimeStatus";
import MyTooltip from "../../../../common/components/extra/Tooltip";

const HEADERS = {
    NAME: "name",
    TIME_IN: "time_in",
    TIME_OUT: "time_out",
    SHIFT_STATUS: "shift_status",
    UPDATED_AT: "updated_at"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = () => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    const createTimeToRender = (time) => (time ? toTimeWithTimeZone(time, timezone).format("hh:mm A") : "--:--");

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "index1";
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.label = "Employee";
                temp.style.width = "15rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const recordStatus = createRecordStatus(row);
                    const isOTOffDay = row.type == WORK_HISTORY_TYPE.OT_OFF_DAY;
                    const shiftOneDate = row.time_in_one && toTimeWithTimeZone(row.time_in_one, timezone).format("MMM DD YYYY");
                    const shiftTwoDate = row.time_out_two && toTimeWithTimeZone(row.time_out_two, timezone).format("MMM DD YYYY");
                    const dateText = createTextFromDate(shiftOneDate, shiftTwoDate, timezone).date;

                    return (
                        <>
                            <LetteredAvatar name={row.full_name} src={row.photo} size={30} small />
                            <div className="flex column gap-05 overflow-hidden">
                                <div className="link-hover" style={{ fontWeight: "bold", width: "max-content" }}>
                                    {sanitizeWords(row.full_name)}
                                </div>
                                <div className="flex gap-05 wrap">
                                    <span className="fade small-font flex center">App:</span>
                                    <Tag>
                                        <span className="text-ellipsis">{row.is_supervisor ? "SUPERVISOR" : "EMPLOYEE"}</span>
                                    </Tag>
                                </div>
                                {!!row?.site_name && (
                                    <div className="flex gap-05 small-font wrap">
                                        <span className="fade flex center">Work Type:</span>
                                        <span className="semi-bold">{sanitizeWords(row?.site_name)}</span>
                                    </div>
                                )}
                                <div className="flex gap-05 small-font wrap">
                                    <span className="fade flex center">Date:</span> <span className="semi-bold">{dateText}</span>
                                </div>
                                <div className="flex gap-05 wrap">
                                    <Tag className={`${recordStatus.className} solid`}>{recordStatus.label}</Tag>
                                    {isOTOffDay && <Tag className="solid">OFF-DAY OT</Tag>}
                                    {recordStatus.tag && <Tag className="solid">{recordStatus.tag}</Tag>}
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }

            case HEADERS.TIME_IN: {
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Time In</span>;
                temp.style.width = "12rem";
                temp.render = (_, row) => {
                    const isSplit = row?.shift_type == SHIFT_TYPE.SPLIT;
                    return (
                        <div className="flex column gap-05">
                            <div className="flex align-center gap-05">
                                <span className="flex gap-03 align-center wrap">
                                    {isSplit && <span className="fade small-font">Shift 1:</span>}
                                    <span className="bold">{createTimeToRender(row.time_in_one)}</span>
                                </span>
                                {row.time_in_one_site && (
                                    <MyTooltip message={createMiniTootlip(row.time_in_one_site)} className="flex">
                                        <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                                    </MyTooltip>
                                )}
                            </div>
                            {isSplit && (
                                <div className="flex align-center gap-05">
                                    <span className="flex gap-03 align-center wrap">
                                        <span className="fade small-font">Shift 2:</span>
                                        <span className="bold">{createTimeToRender(row.time_in_two)}</span>
                                    </span>
                                    {row.time_in_two_site && (
                                        <MyTooltip message={createMiniTootlip(row.time_in_two_site)} className="flex">
                                            <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                                        </MyTooltip>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.TIME_OUT: {
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Time Out</span>;
                temp.style.width = "12rem";
                temp.render = (_, row) => {
                    const isSplit = row?.shift_type == SHIFT_TYPE.SPLIT;
                    return (
                        <div className="flex column gap-05">
                            <div className="flex align-center gap-05">
                                <span className="flex gap-03 align-center wrap">
                                    {isSplit && <span className="fade small-font">Shift 1:</span>}
                                    <span className="bold">{createTimeToRender(row.time_out_one)}</span>
                                </span>
                                {row.time_in_one_site && (
                                    <MyTooltip message={createMiniTootlip(row.time_in_one_site)} className="flex">
                                        <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                                    </MyTooltip>
                                )}
                            </div>
                            {isSplit && (
                                <div className="flex align-center gap-05">
                                    <span className="flex gap-03 align-center wrap">
                                        <span className="fade small-font">Shift 2:</span>
                                        <span className="bold">{createTimeToRender(row.time_out_two)}</span>
                                    </span>
                                    {row.time_out_two_site && (
                                        <MyTooltip message={createMiniTootlip(row.time_out_two_site)} className="flex">
                                            <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                                        </MyTooltip>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.SHIFT_STATUS: {
                temp.style.width = "15rem";
                temp.label = "Shift Status";
                temp.render = (_, row) => {
                    return (
                        <>
                            <ShiftStatus
                                statuses={row.shift_status || []}
                                firstShift={{ start: row.time_in_one, end: row.time_out_one }}
                                secondShift={{ start: row.time_in_two, end: row.time_out_two }}
                                showAvailable={row.shift_type == SHIFT_TYPE.SPLIT}
                                timezone={timezone}
                                simple
                            />
                            <OvertimeStatus
                                statuses={row.shift_status}
                                styles={row.shift_type == SHIFT_TYPE.SPLIT ? { parent: { marginTop: " .5rem" } } : {}}
                                range={row.overtime}
                                timezone={timezone}
                            />
                        </>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
