import React from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading } from "../../features/company/employeeSubmittedForms/slice";
import { selectUser } from "../../features/common/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import SubmittedForms from "../../features/company/employeeSubmittedForms/SubmittedForms";
import Filter from "../../features/company/employeeSubmittedForms/Filter";
import { usePaginateSubmittedForms } from "../../features/company/employeeSubmittedForms/hooks";
import { getFilterCount } from "../../common/utilities/helper";

function EmployeeSubmittedForms() {
    const [, { isLoading, onFilter, onSearch, tableConfig }] = usePaginateSubmittedForms({ readOnly: true });

    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);

    return (
        <PageLayout
            className="tk-employees-submitted-forms"
            title="Manage Submitted Forms"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search }}
            filter={<Filter onFilter={onFilter} isLoading={isLoading} />}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(tableConfig.filter)}
            hasFilter
        >
            <SubmittedForms user={user} />
        </PageLayout>
    );
}

export default EmployeeSubmittedForms;
