import React from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import TableList from "../../../common/components/extra/table/TableList";
import Input from "../../../common/components/extra/Input";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import ViewModal from "./ViewModal";
import { useLazyEmployeeManager } from "./hooks";
import { ASSIGN_TYPE } from "./const";
import UpdateFilesModal from "./UpdateFilesModal";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectData, setCurrent, updateData } from "./slice";

const UNIQUE_KEY = "id";

function EmployeesTableLazy({
    onChange,
    selected,
    readOnly,
    type = ASSIGN_TYPE.DEFAULT,
    excludeIds = [],
    withoutShift,
    onMount,
    enableUncheckedOnshift,
    workShiftId,
    isExpiringWideUI,
    ...rest
}) {
    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const paginatedData = useAppSelector(selectData);

    const {
        unique,
        headers,
        data,
        onSort,
        sort,
        defaultChecked,
        onLoadMore,
        onCheckChange,
        isLoadingMore,
        isLoading,
        onSearch,
        object,
        updateObject,
        reset
    } = useLazyEmployeeManager({
        excludeIds,
        withoutShift,
        enableUncheckedOnshift,
        uniqueKey: UNIQUE_KEY,
        onChange,
        onMount,
        selected,
        readOnly,
        type,
        workShiftId,
        isExpiringWideUI
    });

    const handleFileUpdateFinish = (result) => {
        if (current && current.id == result.id) {
            dispatch(setCurrent({ ...current, ...result }));
        }
        if (paginatedData.length) {
            dispatch(
                updateData({
                    id: result.id,
                    data: {
                        passportID_expiration: result.passportID_expiration,
                        visaID_expiration: result.visaID_expiration,
                        residenceID_expiration: result.residenceID_expiration,
                        contract_expiration: result.contract_expiration
                    }
                })
            );
        }
        updateObject({ selected: null, viewFile: false });
        reset();
    };

    return (
        <>
            <div className="flex column gap-1">
                <Input parentStyle={{ maxWidth: "25rem" }} onChange={debounce(onSearch, 1000)} isSearch />
                <TableList
                    headers={headers}
                    data={data}
                    onSort={onSort}
                    activeSort={sort}
                    uniqueKey={unique}
                    checked={{ id: defaultChecked }}
                    onLoadMore={onLoadMore}
                    onCheckChange={onCheckChange}
                    height={35}
                    isLoadingMore={isLoadingMore}
                    isLoading={isLoading}
                    {...rest}
                />
            </div>
            {object.viewFile && (
                <UpdateFilesModal
                    id={object?.selected?.id}
                    open={object.viewFile}
                    onClose={() => updateObject({ selected: null, viewFile: false })}
                    onFinish={handleFileUpdateFinish}
                />
            )}
            {object.viewRecord && (
                <ViewModal open={object.viewRecord} onClose={() => updateObject({ selected: null, viewRecord: false })} id={object?.selected?.id} />
            )}
            {object.viewSites && (
                <ViewGeozonesModal
                    open={object.viewSites}
                    onChange={(bool) => {
                        if (!bool) {
                            updateObject({ viewSites: false, selected: null });
                        }
                    }}
                    data={object.selected.CompanySites.map((cs) => cs.id) || []}
                />
            )}
        </>
    );
}

EmployeesTableLazy.propTypes = {
    open: PropTypes.bool,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    onConfirm: PropTypes.func,
    onMount: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ASSIGN_TYPE)),
    excludeIds: PropTypes.array,
    withoutShift: PropTypes.bool,
    enableUncheckedOnshift: PropTypes.bool,
    workShiftId: PropTypes.any,
    height: PropTypes.number,
    isExpiringWideUI: PropTypes.bool
};

export default EmployeesTableLazy;
