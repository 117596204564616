import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazyEmployees } from "./hooks";
import { SORT_ORDER } from "../../../common/utilities/const";

function EmployeeSelectLazy(props) {
    const [object, , { loadMore, search, isLoading, createRowItem }] = useLazyEmployees({
        initializing: props.isLoading,
        value: props?.value,
        allowOnShift: props.allowOnShift,
        allowSelectOnShift: props.allowSelectOnShift,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter,
        showDepartment: props.showDepartment,
        excludeIds: props.excludeIds,
        sortBy: props.sortBy,
        sortOrder: props.sortOrder
    });

    let getValue = !props.isMulti
        ? props.value && Object.keys(props.value).length && object.data.find((item) => item.id === props.value.id)
        : object.data.filter((item) => props.value.map((v) => v.id).includes(item.id));

    if (!props.isMulti ? !!Object.keys(props.value || {}).length : Array.isArray(props.value) && props.value.length) {
        if (!props.isMulti) {
            if (!getValue) {
                getValue = createRowItem(props.value);
            }
        } else {
            getValue = props.value.map((item) => createRowItem(item));
        }
    }

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    return (
        <AsyncSelectLazy
            {...props}
            filterOption={(options, newInputs) => {
                const fullName = `${options?.data?.first_name || ""} ${options?.data?.last_name || ""}`.trim().toLowerCase();
                const index1 = options?.data?.index1?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (index1.includes(search) || fullName.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            value={props.isLoading ? null : getValue}
            options={object.data}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={props.isLoading || isLoading}
        />
    );
}

EmployeeSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    showDepartment: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    allowOnShift: PropTypes.bool,
    isLoading: PropTypes.bool,
    allowSelectOnShift: PropTypes.bool,
    isFilter: PropTypes.bool,
    excludeIds: PropTypes.arrayOf(PropTypes.number),
    sortBy: PropTypes.string,
    sortOrder: PropTypes.oneOf(Object.values(SORT_ORDER))
};

export default EmployeeSelectLazy;
