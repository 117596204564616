import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArrowIcon from "@mui/icons-material/ArrowForward";
import { createConfirmAlert, renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { FORM_STATUS } from "./const";
import { createUserLevelOptions } from "./UserLevelSelect";
import MyTooltip from "../../../common/components/extra/Tooltip";

const HEADERS = {
    NAME: "name",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "created_at",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.label = "Name";
                temp.sortKey = "name";
                temp.style.width = "20rem";
                temp.render = (_, row) => {
                    const approvalLevels = [...row.approval_levels];
                    approvalLevels.sort((a, b) => a - b);
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <div className="flex gap-05 text-ellipsis wrap" style={{ alignItems: "center" }}>
                                <div className="text-ellipsis" style={{ fontWeight: "bold" }}>
                                    {sanitizeWords(row.name)}
                                </div>
                                <div className="flex gap-05 wrap">
                                    <Tag className={row.status == FORM_STATUS.ACTIVE ? "green" : "red"}>
                                        <span className="text-ellipsis">{sanitizeWords(row.status)}</span>
                                    </Tag>
                                </div>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Form Type:</span>
                                <span className="text-ellipsis semi-bold">{sanitizeWords(row.type)}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Min. User Level:</span>
                                <span className="text-ellipsis semi-bold">
                                    {createUserLevelOptions().find((usr) => usr.value == row.min_user_level).label}
                                </span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Approval Type:</span>
                                <span className="text-ellipsis semi-bold">{sanitizeWords(row.approval_type)}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Processing Levels:</span>
                                <div className="flex align-center" style={{ height: "1rem" }}>
                                    {approvalLevels.map((level, i) => (
                                        <React.Fragment key={level}>
                                            <MyTooltip
                                                element="span"
                                                className="circle-tag xs-font"
                                                message={createUserLevelOptions().find((usr) => usr.value == level).label}
                                            >
                                                {level}
                                            </MyTooltip>
                                            {i != approvalLevels.length - 1 && <ArrowIcon className="fade" style={{ width: "1rem" }} />}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {toReadableFromDate(
                                toReadableFromDate(row.updatedAt, setting.timezone) == toReadableFromDate(row.createdAt, setting.timezone)
                                    ? ""
                                    : row.updatedAt,
                                setting.timezone
                            ) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <EditIcon onClick={() => typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.EDIT)} />
                        <DeleteIcon
                            style={{ color: "red" }}
                            onClick={async () => {
                                createConfirmAlert({
                                    title: "Remove Form",
                                    content: "Are you sure you want to remove this form? This cannot be undone.",
                                    onConfirm: async (close) => {
                                        close();
                                        typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                    }
                                });
                            }}
                        />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
