export const TABS = {
    EMP_COMPANY_FORM: { id: "employee-c-form", label: "Employees' Forms", isActive: true },
    HR_COMPANY_FORM: { id: "hr-c-form", label: "HR Forms", isActive: true }
};

export const FILTER_TYPE = {
    TYPE: 0x1,
    APPROVAL_TYPE: 0x2,
    MIN_USER_LEVEL: 0x3,
    STATUS: 0x4,
    DATE: 0x5,
    CATEGORY: 0x6 //  only use for tabs
};

export const USER_LEVEL = {
    ZERO: 0x0, // Default as Employee/Staff and upon creation
    ONE: 0x1, // Supervisor/Site Engineer/Foreman
    TWO: 0x2, // Department Manager/Site Manager
    THREE: 0x3, // HR Department/Accounts Department/Finance Department
    FOUR: 0x4 // (form and report viewing and approvals only) - GM/Director/Owner/CEO
};

export const FIELD = {
    NAME: "name",
    TYPE: "type",
    FIELDS: "fields",
    STATUS: "status",
    CATEGORY: "category",
    MIN_USER_LEVEL: "min_user_level",
    APPROVAL_TYPE: "approval_type",
    APPROVAL_LEVELS: "approval_levels"
};

export const FIELD_OBJECT = {
    [FIELD.NAME]: {
        label: "Name",
        default: "",
        required: true
    },
    [FIELD.TYPE]: {
        label: "Type",
        default: "",
        required: true
    },
    [FIELD.FIELDS]: {
        label: "Fields",
        default: "",
        required: true
    },
    [FIELD.CATEGORY]: {
        label: "Category",
        default: "",
        required: true
    },
    [FIELD.MIN_USER_LEVEL]: {
        label: "Min User Level",
        default: USER_LEVEL.ZERO,
        required: true
    },
    [FIELD.APPROVAL_TYPE]: {
        label: "Approval Type",
        default: "",
        required: true
    },
    [FIELD.APPROVAL_LEVELS]: {
        label: "Processing Levels",
        default: [USER_LEVEL.THREE],
        required: true
    },
    [FIELD.STATUS]: {
        label: "Status",
        default: "",
        required: true
    }
};

export const VALIDATION_FIELD = {
    LABEL: "label",
    VALUE: "value",
    MIN: "min",
    MAX: "max",
    REQUIRED: "required"
};

export const VALIDATION_LABEL = {
    [VALIDATION_FIELD.LABEL]: "Label",
    [VALIDATION_FIELD.VALUE]: "Value",
    [VALIDATION_FIELD.MIN]: "Min",
    [VALIDATION_FIELD.MAX]: "Max",
    [VALIDATION_FIELD.REQUIRED]: "Required"
};

export const FORM_CATEGORY = {
    EMPLOYEE: "EMPLOYEE", // submitted by the employees from the mobapp
    HR: "HR" // sent by the WebAdmin to a target employee
};

export const FORM_APPROVAL_TYPE = {
    DEPARTMENT: "DEPARTMENT", // if there's no direct and SV and MG, this will be the approval flow
    SITE: "SITE" // this will be used for timing related requests (WorkHistory)
};

export const FORM_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};
