import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    selectEmployeeSubmittedFormData,
    setCurrent,
    setState,
    updateEmployeeSubmittedFormData,
    defaultConfig
} from "./slice";
import { useGetEmployeeSubmittedFormsMutation, useLoadAllEmployeeSubmittedFormsMutation, useApproveEmployeeSubmittedFormsMutation } from "./api";
import { SUBMITTED_FORM_STATUS } from "../../../common/utilities/const";
import { SUBMITTED_FORM_TYPE } from "./const";
import { useGetWorkHistory } from "../employeeWorkHistories/hooks";
import { EDIT_TYPE } from "../employeeWorkHistories/const";
import { defaultTableConfig, setState as setHistoryState } from "../employeeWorkHistories/slice";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

export const useGetSubmittedForm = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetEmployeeSubmittedFormsMutation();

    const [history, { fetch: fetchHistory, clearCurrent: clearHistory }] = useGetWorkHistory();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const getHistoryEditType = () => {
        let editType = EDIT_TYPE.DEFAULT;
        if (!current) {
            return editType;
        }
        if (current.type == SUBMITTED_FORM_TYPE.WH_TIMING) {
            editType = EDIT_TYPE.TIMING;
        }
        if (current.type == SUBMITTED_FORM_TYPE.WH_OVERTIME) {
            editType = EDIT_TYPE.OVERTIME;
        }
        if (current.status != SUBMITTED_FORM_STATUS.PENDING && current.type != SUBMITTED_FORM_TYPE.WH_ABSENT) {
            editType = EDIT_TYPE.READ_ONLY;
        }
        return editType;
    };

    const createVars = (data) => {
        if (!data) return {};
        return {
            toUpdateHistoryId: data.formData?.historyId,
            toCreateHistoryDate: data.formData?.date,
            isRejected: data.status == SUBMITTED_FORM_STATUS.REJECTED,
            isApproved: data.status == SUBMITTED_FORM_STATUS.APPROVED,
            isPending: data.status == SUBMITTED_FORM_STATUS.PENDING,
            history,
            historyEditType: getHistoryEditType()
        };
    };

    const fetch = async ({ force } = {}) => {
        try {
            if (!force && current && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch submitted forms. Please try again later");
            }
            const finRes = cloneDeep(result.data.data);
            const historyId = finRes && finRes.formData?.historyId;
            await handleFetchHistory(historyId);
            dispatch(setCurrent(finRes));
            return finRes;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const handleFetchHistory = (newid) => {
        const historyId = newid || (current && current.formData?.historyId);
        if (historyId) {
            return fetchHistory(historyId);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));

    const clearCurrent = ({ onlyHistory } = {}) => {
        !onlyHistory && dispatch(setCurrent(null));
        clearHistory();
    };

    useEffect(() => {
        fetch();
    }, []);

    return [current, { isLoading: fetching, config: createVars(current), updateCurrent, fetch, clearCurrent, fetchHistory: handleFetchHistory }];
};

export const usePaginateSubmittedForms = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, onSort, onUpdate, tableConfig }] = usePaginateFetch(
        useLoadAllEmployeeSubmittedFormsMutation,
        {
            redux: {
                dataSelector: selectEmployeeSubmittedFormData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch }];
};

export const useUpdateSubmittedFormStatus = (updateId) => {
    const [update, { isLoading }] = useApproveEmployeeSubmittedFormsMutation();
    const [, { fetch: fetchHistory }] = useGetWorkHistory();

    const dispatch = useAppDispatch();

    const updateStatus = async (newObject = {}) => {
        try {
            if (!("isReject" in newObject)) {
                throw new Error("Select a status first.");
            }
            const clonedform = cloneDeep(newObject);
            const result = await update({ body: { isReject: clonedform.isReject, historyId: clonedform.historyId }, extraPath: updateId });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Submitted form status updated succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            dispatch(
                updateEmployeeSubmittedFormData({
                    id: updateId,
                    data: {
                        status: newObject.isReject ? SUBMITTED_FORM_STATUS.REJECTED : SUBMITTED_FORM_STATUS.APPROVED
                    }
                })
            );
            if (result.data.data) {
                const finRes = cloneDeep(result.data.data);
                const historyId = finRes && finRes.formData?.historyId;
                if (historyId) {
                    await fetchHistory(historyId, true);
                }
                dispatch(setCurrent(finRes));
                dispatch(
                    setHistoryState({
                        data: [],
                        tableConfig: defaultTableConfig
                    })
                );
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to update the status of the submitted form. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };
    return [updateStatus, isLoading];
};
