import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import TimePickerRange from "../../../common/components/extra/dateTime/TimePickerRange";
import Divider from "../../../common/components/extra/Divider";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { EDIT_TYPE, FIELDS, TIME_TYPE } from "./const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { createTextFromDate, renderNA, sanitizeWords } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { NOTES_MAX_LEN, SCHEDULE_TYPE, STANDARD_DATE_FORMAT, WORK_HISTORY_TYPE } from "../../../common/utilities/const";
import OvertimeStatus from "./OvertimeStatus";
import ShiftStatus from "./ShiftStatus";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import WorkshiftReference from "./WorkshiftReference";

const { TYPE, WORK_TYPE_ID, WORK_SHIFT_ID, SITE_ONE_ID, SITE_TWO_ID, OVERTIME_SITE_ID, NOTE } = FIELDS;

const TIMEPICKER_PROPS = {
    menuPlacement: "top",
    isPortal: true,
    interval: 1
};

function FormUpdateInputs({
    schedType,
    form,
    config,
    disableFields,
    handleFormChange,
    handleTimeChange,
    setting,
    isOff,
    isUpdate,
    editType,
    date,
    isForceOTOffDay
}) {
    const [initialData, setInitialData] = useState({});

    const settings = useAppSelector(selectUserSetting);
    const timezone = settings.timezone;
    const isDefaultEditType = (isUpdate && editType == EDIT_TYPE.DEFAULT) || !isUpdate;
    const isOverTimeEditType = isUpdate && editType == EDIT_TYPE.OVERTIME;
    const isTimingEditType = isUpdate && editType == EDIT_TYPE.TIMING;
    const isReadOnly = isUpdate && editType == EDIT_TYPE.READ_ONLY;
    const isFlexibleTime = schedType == SCHEDULE_TYPE.FLEXIBLE_TIME;
    const isOTOffDay = form[TYPE.name] == WORK_HISTORY_TYPE.OT_OFF_DAY;

    useEffect(() => {
        if (isUpdate) {
            setInitialData(form);
        }
    }, []);

    const info = useMemo(() => {
        if (!config.workShift) {
            return {};
        }

        const workshift = cloneDeep(config.workShift);
        const name = sanitizeWords(workshift.title);
        const type = sanitizeWords(workshift.shift_type);
        const date = createTextFromDate(workshift.start_time, workshift.end_time_2, timezone).date;
        const shiftOneTime = createTextFromDate(workshift.start_time, workshift.end_time, timezone).time || renderNA("None");
        const shiftTwoTime = createTextFromDate(workshift.start_time_2, workshift.end_time_2, timezone).time || renderNA("None");

        const maxBreakDuration = config.maxBreakDuration ? `${config.maxBreakDuration} hour(s)` : renderNA("None");

        const shiftOneBreak = config.hasBreakOne
            ? createTextFromDate(workshift.break_time, workshift.break_end_time, timezone).time || "No Break"
            : config.maxBreakDuration
              ? "Flexible Break"
              : "No Break";

        const shiftTwoBreak = config.hasBreakTwo
            ? createTextFromDate(workshift.break_time_2, workshift.break_end_time_2, timezone).time || "No Break"
            : config.maxBreakDuration
              ? "Flexible Break"
              : "No Break";

        const maxOverTime = workshift.max_overtime ? `${workshift.max_overtime} hour(s)` : renderNA("None");
        const reqShiftHours = workshift.required_shift_time ? `${workshift.required_shift_time} hour(s)` : renderNA("None");

        return {
            name,
            type,
            date,
            shiftOneTime,
            shiftTwoTime,
            shiftOneBreak,
            shiftTwoBreak,
            maxOverTime,
            maxBreakDuration,
            reqShiftHours,
            hasBreakOne: config.hasBreakOne,
            hasBreakTwo: config.hasBreakTwo,
            isSplit: config.isSplit,
            isFlexibleTime,
            schedType: sanitizeWords(schedType),
            halfdayInfo: config.halfdayInfo
        };
    }, [config.workShift, config.maxBreakDuration, config.hasBreakOne, config.hasBreakTwo, config.isSplit, timezone]);

    return (
        <div className="flex column gap-05">
            <SelectConstant
                base={WORK_HISTORY_TYPE}
                label="History Type"
                onChange={(target) => handleFormChange({ name: TYPE.name, value: target.value })}
                value={form?.[TYPE.name]}
                isDisabled={disableFields || isOff || !isDefaultEditType || isForceOTOffDay}
                isOutlined
                disabledOutline
                required
            />
            {isOff && (
                <span className="fade small-font bold" style={{ color: "red", marginTop: ".3rem" }}>
                    Type will automatically be {"'OFF'"} if the data is affected.
                </span>
            )}
            <WorkDetailSelectLazy
                label="Work Type"
                value={form[WORK_TYPE_ID.name]}
                onChange={(target) => handleFormChange({ name: WORK_TYPE_ID.name, value: target })}
                isDisabled={disableFields || isUpdate}
                isSearchable={false}
                allowInUse
                required
                isOutlined
                disabledOutline
            />
            <WorkShiftSelectLazy
                label="Work Shift"
                value={form[WORK_SHIFT_ID.name]}
                onChange={(target) => handleFormChange({ name: WORK_SHIFT_ID.name, value: target })}
                isDisabled={disableFields || isUpdate}
                isSearchable={false}
                required
                isOutlined
                disabledOutline
            />
            {!config.workShift && (
                <span className="fade small-font bold" style={{ color: "red", marginTop: ".3rem" }}>
                    Please select a workshift to display additional input fields below.
                </span>
            )}
            {config.workShift && (
                <>
                    <SectionCollapseInfo title={<span className="small-font">Work Shift Info Reference (Recommended)</span>} show>
                        <WorkshiftReference info={info} />
                    </SectionCollapseInfo>
                    <div className="flex column" style={{ marginTop: "1rem" }}>
                        <Divider
                            title={
                                <div className="flex gap-05 center">
                                    <span style={{ whiteSpace: "nowrap" }}>{!config.isSplit ? "Shift" : "Shift One"}</span>
                                    {(config.shiftOne.range?.start?.format() || config.shiftOne.range?.start) &&
                                        (config.shiftOne.range?.end?.format() || config.shiftOne.range?.end) && (
                                            <ShiftStatus
                                                statuses={config.shiftStatus}
                                                firstShift={{
                                                    start: config.shiftOne.range?.start?.format() || config.shiftOne.range?.start,
                                                    end: config.shiftOne.range?.end?.format() || config.shiftOne.range?.end
                                                }}
                                                simple
                                                noTitle
                                                onlyShiftOne
                                            />
                                        )}
                                </div>
                            }
                        />
                        <SiteSelectLazy
                            label="Site"
                            value={form[SITE_ONE_ID.name]}
                            onChange={(target) => handleFormChange({ name: SITE_ONE_ID.name, value: target })}
                            isDisabled={disableFields || !!(isUpdate && initialData[SITE_ONE_ID.name]) || isOverTimeEditType || isReadOnly}
                            required
                            isOutlined
                            disabledOutline
                        />
                        <TimePickerRange
                            label="Time"
                            range={config.shiftOne.range}
                            constraint={config.shiftOne.constraint}
                            onChange={(conf, _, event) => handleTimeChange(TIME_TYPE.SHIFT_ONE, conf, event)}
                            timezone={setting.timezone}
                            disabled={disableFields || isOverTimeEditType || isReadOnly}
                            baseDate={date && moment(date).format(STANDARD_DATE_FORMAT)}
                            required
                            {...TIMEPICKER_PROPS}
                        />
                        {info.shiftOneBreak && info.shiftOneBreak != "No Break" && (
                            <TimePickerRange
                                label="Break"
                                range={config.breakOne.range}
                                constraint={config.breakOne.constraint}
                                onChange={(conf, _, event) => handleTimeChange(TIME_TYPE.BREAK_ONE, conf, event)}
                                timezone={setting.timezone}
                                disabled={disableFields || isOverTimeEditType || isReadOnly}
                                required={!!config.breakOne.range.start}
                                baseDate={date && moment(date).format(STANDARD_DATE_FORMAT)}
                                noBorder
                                {...TIMEPICKER_PROPS}
                            />
                        )}
                    </div>
                    {config.isSplit && (
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Divider
                                title={
                                    <div className="flex gap-05 center">
                                        <span style={{ whiteSpace: "nowrap" }}>Shift Two</span>
                                        {(config.shiftTwo.range?.start?.format() || config.shiftTwo.range?.start) &&
                                            (config.shiftTwo.range?.end?.format() || config.shiftTwo.range?.end) && (
                                                <ShiftStatus
                                                    statuses={config.shiftStatus}
                                                    secondShift={{
                                                        start: config.shiftTwo.range?.start?.format() || config.shiftTwo.range?.start,
                                                        end: config.shiftTwo.range?.end?.format() || config.shiftTwo.range?.end
                                                    }}
                                                    simple
                                                    noTitle
                                                    onlyShiftTwo
                                                />
                                            )}
                                    </div>
                                }
                            />
                            <SiteSelectLazy
                                label="Site"
                                value={form[SITE_TWO_ID.name]}
                                onChange={(target) => handleFormChange({ name: SITE_TWO_ID.name, value: target })}
                                isDisabled={disableFields || !!(isUpdate && initialData[SITE_TWO_ID.name]) || !config.hasShiftTwo}
                                menuPlacement="top"
                                required={!!config.breakTwo.range.start || !!config.shiftTwo.range.start || isOverTimeEditType || isReadOnly}
                                isClearable
                                isOutlined
                                disabledOutline
                            />
                            <TimePickerRange
                                label="Time"
                                range={config.shiftTwo.range}
                                constraint={config.shiftTwo.constraint}
                                onChange={(conf, _, event) => handleTimeChange(TIME_TYPE.SHIFT_TWO, conf, event)}
                                timezone={setting.timezone}
                                disabled={disableFields || !config.hasShiftTwo || isOverTimeEditType || isReadOnly}
                                required={!!form[SITE_TWO_ID.name]}
                                baseDate={config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)}
                                {...TIMEPICKER_PROPS}
                            />
                            {info.shiftTwoBreak && info.shiftTwoBreak != "No Break" && (
                                <TimePickerRange
                                    label="Break"
                                    range={config.breakTwo.range}
                                    constraint={config.breakTwo.constraint}
                                    onChange={(conf, _, event) => handleTimeChange(TIME_TYPE.BREAK_TWO, conf, event)}
                                    timezone={setting.timezone}
                                    disabled={disableFields || !config.hasShiftTwo || isOverTimeEditType || isReadOnly}
                                    required={!!config.breakTwo.range.start}
                                    baseDate={config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)}
                                    noBorder
                                    {...TIMEPICKER_PROPS}
                                />
                            )}
                        </div>
                    )}
                    {!isOTOffDay && (
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Divider
                                title={
                                    <div className="flex gap-05 center">
                                        Overtime
                                        <OvertimeStatus
                                            statuses={config.shiftStatus}
                                            range={{
                                                start: config.overtime.range?.start?.format?.() || config.overtime.range.start,
                                                end: config.overtime.range?.end?.format?.() || config.overtime.range.end
                                            }}
                                            timezone={timezone}
                                        />
                                    </div>
                                }
                            />
                            <SiteSelectLazy
                                label="Site"
                                value={form[OVERTIME_SITE_ID.name]}
                                onChange={(target) => handleFormChange({ name: OVERTIME_SITE_ID.name, value: target })}
                                isDisabled={disableFields || !!(isUpdate && initialData[OVERTIME_SITE_ID.name]) || isTimingEditType || isReadOnly}
                                menuPlacement="top"
                                required={!!config.overtime.range.start}
                                isClearable
                                isOutlined
                                disabledOutline
                            />
                            <TimePickerRange
                                label="Time"
                                range={config.overtime.range}
                                constraint={config.overtime.constraint}
                                onChange={(conf, event) => handleTimeChange(TIME_TYPE.OVERTIME, conf, event)}
                                timezone={setting.timezone}
                                disabled={disableFields || isTimingEditType || isReadOnly}
                                required={!!config.overtime.range.start || !!form[OVERTIME_SITE_ID.name]}
                                baseDate={
                                    config.isSplit
                                        ? config.shiftTwo.range.end && config.shiftTwo.range.end.format(STANDARD_DATE_FORMAT)
                                        : config.shiftOne.range.end && config.shiftOne.range.end.format(STANDARD_DATE_FORMAT)
                                }
                                {...TIMEPICKER_PROPS}
                            />
                        </div>
                    )}
                    <div style={{ marginTop: "1rem" }}>
                        <Input
                            type={INPUT_TYPE.TEXTAREA}
                            label="Note"
                            name={NOTE.name}
                            value={form[NOTE.name]}
                            placeholder="Your note here..."
                            onChange={(e) => handleFormChange({ name: e.target.name, value: e.target.value })}
                            disabled={disableFields || isReadOnly}
                            maxLength={NOTES_MAX_LEN}
                            parentStyle={{ height: "10rem" }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default FormUpdateInputs;

FormUpdateInputs.propTypes = {
    form: PropTypes.object,
    config: PropTypes.object,
    disableFields: PropTypes.bool,
    handleFormChange: PropTypes.func,
    handleTimeChange: PropTypes.func,
    setting: PropTypes.object,
    isOff: PropTypes.bool,
    isUpdate: PropTypes.bool,
    hasChanges: PropTypes.bool,
    hasDefaultEmployee: PropTypes.bool,
    editType: PropTypes.oneOf(Object.values(EDIT_TYPE)),
    schedType: PropTypes.oneOf(Object.values(SCHEDULE_TYPE)),
    date: PropTypes.any,
    isForceOTOffDay: PropTypes.bool
};
