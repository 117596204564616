import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import isEqual from "lodash/isEqual";
import MyTooltip from "../Tooltip";
import Editor from "../richTextEditor/Editor";

function InputTextArea({ style, tooltip, isRichTextEditor, includeImageUpload, uploadConfig, hasHTMLwrapper, ...inputProps }) {
    const quilRef = useRef();

    const [char, setChar] = useState(!isRichTextEditor ? inputProps?.value?.length || 0 : 0);
    const [, setLastChange] = useState([]);
    const [value, setValue] = useState(inputProps.value);

    useEffect(() => {
        if (!isEqual(value, inputProps.value)) {
            setValue(inputProps.value);
        }
    }, [inputProps.value]);

    const handleChange = (e) => {
        const value = e.target.value;
        setValue(value);
        setChar(value?.length || 0);
        typeof inputProps.onChange === "function" && inputProps.onChange(e);
    };

    const handleTextChange = (delta, value) => {
        setLastChange(delta);
        setChar(value?.text?.length || 0);
        typeof inputProps.onChange === "function" && inputProps.onChange({ target: { value, name: inputProps.name } });
    };

    return (
        <div className="tk-input__textarea" style={style || {}}>
            {inputProps.label && (
                <div className="tk-input__label flex gap-05" style={{ alignItems: "center" }}>
                    <span style={{ whiteSpace: "nowrap" }}>
                        {inputProps.label}
                        {inputProps.required && !inputProps.readOnly && !inputProps.disabled ? <span className="danger-color bold">*</span> : ""}
                    </span>
                    {tooltip && (
                        <MyTooltip message={tooltip} className="flex">
                            <InfoIcon className="hover-svg" style={{ width: "1rem" }} />
                        </MyTooltip>
                    )}
                </div>
            )}
            <div className={`tk-input__textarea__content ${isRichTextEditor ? "rich-text" : ""}`.trim()}>
                {isRichTextEditor ? (
                    <Editor
                        ref={quilRef}
                        defaultValue={value}
                        onTextChange={(conf, html) => handleTextChange(conf.ops, html)}
                        limit={inputProps.maxLength}
                        onRenderChange={(text, option) => setChar(option.length)}
                        includeImageUpload={includeImageUpload}
                        uploadConfig={uploadConfig}
                        placeholder={inputProps.placeholder}
                        hasHTMLwrapper={hasHTMLwrapper}
                    />
                ) : (
                    <textarea {...inputProps} onChange={handleChange} value={value} spellCheck={false} />
                )}
            </div>
            {(isRichTextEditor || inputProps.maxLength) && (
                <span className="fade xs-font" style={{ marginLeft: "auto" }}>
                    {!isRichTextEditor ? (!char ? inputProps?.value?.length || 0 : char) : char} characters
                </span>
            )}
        </div>
    );
}

InputTextArea.propTypes = {
    onChange: PropTypes.func,
    style: PropTypes.object,
    tooltip: PropTypes.string,
    isRichTextEditor: PropTypes.bool,
    maxLength: PropTypes.number,
    includeImageUpload: PropTypes.bool,
    uploadConfig: PropTypes.shape({
        image: PropTypes.shape({ size: PropTypes.number })
    }),
    hasHTMLwrapper: PropTypes.bool
};

export default InputTextArea;
