import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import CreateTableHeaders from "./CreateTableHeaders";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import { setCurrent } from "./slice";
import UpdateFilesModal from "./UpdateFilesModal";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { CUSTOM_ACTION } from "./const";
import { usePaginateEmployees } from "./hooks";
import UpdateDepartmentModal from "./UpdateDepartmentModal";

function Employees({ openAddModal, onAddModalClose }) {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [openViewFileModal, setOpenViewFileModal] = useState(false);
    const [openViewDeptModal, setOpenViewDeptModal] = useState(false);
    const [selected, setSelected] = useState(null);

    const [data, { isLoading: isPaginating, fetch, update, reset, isFilterOnDefault, tableConfig }] = usePaginateEmployees();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
        setOpenViewFileModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = () => {
        reset();
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                setOpenViewModal(true);
                setSelected(row);
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                reset();
                break;
            case CUSTOM_ACTION.VIEW_FILES:
                setSelected(row);
                setOpenViewFileModal(true);
                break;
            case CUSTOM_ACTION.VIEW_DEPARTMENT:
                setSelected(row);
                setOpenViewDeptModal(true);
                break;
            case CUSTOM_ACTION.RESET_DEVICE:
                if (!isFilterOnDefault) {
                    reset();
                }
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => {
        setSelected(null);
        setOpenViewModal(false);
    };

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    const handleUpdateFileFinish = (result) => {
        if (isFilterOnDefault) {
            update(result.id, {
                passportID_expiration: result.passportID_expiration,
                visaID_expiration: result.visaID_expiration,
                residenceID_expiration: result.residenceID_expiration,
                contract_expiration: result.contract_expiration
            });
        } else {
            reset();
        }
        handleModalBack();
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                isLoading={isPaginating}
                tableConfig={tableConfig}
            />
            {openViewFileModal && (
                <UpdateFilesModal
                    id={selected?.id}
                    open={openViewFileModal}
                    onClose={() => setOpenViewFileModal(false)}
                    onFinish={handleUpdateFileFinish}
                />
            )}
            {openViewDeptModal && (
                <UpdateDepartmentModal
                    id={selected?.id}
                    open={openViewDeptModal}
                    onClose={() => setOpenViewDeptModal(false)}
                    onFinish={() => setOpenViewDeptModal(false)}
                />
            )}
            {openViewModal && <ViewModal open={openViewModal} onClose={handleViewModalClose} id={selected?.id} onEdit={handleModalEdit} />}
            {openEditModal && (
                <UpdateModal
                    id={selected?.id}
                    open={openEditModal}
                    onClose={handleUpdateModalClose}
                    onBack={handleModalBack}
                    onFinish={handleFinish}
                />
            )}
        </>
    );
}

Employees.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Employees;
