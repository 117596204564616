import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import ViewModalSub from "./ViewModalSub";
import { selectUserSetting, updateUser } from "../../common/slice";
import { BASE_CLASS_UPDATE_MODAL, EMPLOYEE_LOCATION_TYPES, PASSWORD_KEYS_TO_USE, PERSONAL_FIELDS } from "./const";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import Divider from "../../../common/components/extra/Divider";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Tag from "../../../common/components/extra/Tag";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import DesignationsSelectLazy from "../designations/DesignationsSelectLazy";
import {
    CONTRACT_TERM,
    CONTRACT_TYPE,
    DAY_ABBREVIATION,
    GENDER,
    MARITAL_STATUS,
    SALARY_FREQUENCY,
    EMPLOYEE_FILES,
    SCHEDULE_TYPE,
    CONTRACT_STATUS
} from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    createClass,
    createGroup,
    createToast,
    formattedJoiErrorMessage,
    isObjectEqualWithBase,
    sanitizeWords,
    separateLastCharacters
} from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { PAYMENT_MODE } from "../employeesSalary/const";
import AppRoleSelect from "./AppRoleSelect";
import InputEmployeeFile from "./InputEmployeeFile";
import { useUpsertEmployee } from "./hooks";
import SelectNationality from "../../../common/components/extra/select/SelectNationality";
import SelectBank from "../../../common/components/extra/select/SelectBank";
import UpdateFilesInputs from "./UpdateFilesInputs";
import EmployeeSelectLazy from "./EmployeeSelectLazy";
import UserLevelSelect from "../companyForms/UserLevelSelect";

function UpdateModal({ open, onChange, onClose, onBack, onFinish, id }) {
    const [error, setError] = useState(null);
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [upsert, isLoading, { onFormChange, form, isCreate, current, isGettingEmployee }] = useUpsertEmployee(id);

    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const renderCurrencyExtra = <span>{setting.currency}</span>;
    const isShiftEditable = !current?.isOnShift;

    const dispatch = useAppDispatch();

    const handleSave = async () => {
        try {
            let clonedform = cloneDeep(form);
            const result = await upsert();
            if (result) {
                if (isCreate) {
                    const totalEmployees = result?.employeeCount;
                    dispatch(updateUser({ totalEmployees }));
                }
                createToast(
                    `Employee [${sanitizeWords(clonedform?.first_name)} ${sanitizeWords(clonedform?.last_name)}] ${
                        isCreate ? "created" : "updated"
                    } succesfully.`,
                    TOAST_TYPE.SUCCESS
                );
            } else {
                createToast(result.data.message, TOAST_TYPE.SUCCESS);
            }
            if (result && typeof onFinish === "function") {
                onFinish(result);
            }
            setError(null);
            onBack?.();
        } catch (error) {
            setError({ message: error?.message || error });
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Employee: ${sanitizeWords(form.first_name)} ${sanitizeWords(form.last_name)}!. ${
                    error?.message || "Please try again later or contact support."
                } `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const handleChange = (e) => onFormChange(e);

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={handleSave}
            isLoading={isLoading}
            disableSave={isLoading || isGettingEmployee || (isObjectEqualWithBase(form, current) && isEqual(form.uploads, current.uploads))}
            styles={{ content: { width: "70vw" } }}
            isForm
        >
            <div className={createClass(BASE_CLASS_UPDATE_MODAL)}>
                <div className={createClass("__inner", BASE_CLASS_UPDATE_MODAL)}>
                    <SectionCollapseWarning show={!isCreate && !isShiftEditable}>
                        Editing capabilities will be limited because employee currently in shift.
                    </SectionCollapseWarning>
                    <SectionCollapseError show={!!error}>{formattedJoiErrorMessage({ error, isCreate })}</SectionCollapseError>

                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)} style={{ marginBottom: "2rem" }}>
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Mobile App Access",
                            body: (
                                <>
                                    <SelectConstant
                                        base={PASSWORD_KEYS_TO_USE}
                                        transformBase={(opt) => ({
                                            ...opt,
                                            clean: separateLastCharacters(sanitizeWords(opt.value), 2, "id").toUpperCase(),
                                            label: <Tag className="brown">{separateLastCharacters(sanitizeWords(opt.value), 2, "id")}</Tag>
                                        })}
                                        label="Password to Use"
                                        value={form.passwordKeyToUse}
                                        onChange={(val) => handleChange({ target: { name: "passwordKeyToUse", value: val.value } })}
                                        menuPlacement="bottom"
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <AppRoleSelect
                                        value={form?.Role?.id}
                                        onChange={(value) =>
                                            handleChange({
                                                target: {
                                                    name: "Role",
                                                    value: { id: value.id, name: value.name }
                                                }
                                            })
                                        }
                                    />
                                    <UserLevelSelect
                                        value={form?.user_level}
                                        onChange={(value) =>
                                            handleChange({
                                                target: {
                                                    name: PERSONAL_FIELDS.USER_LEVEL,
                                                    value: value.value
                                                }
                                            })
                                        }
                                        label="User level"
                                        menuPlacement="bottom"
                                        isLoading={isGettingEmployee}
                                        isTag
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <SelectConstant
                                        base={EMPLOYEE_LOCATION_TYPES}
                                        transformBase={(value) => ({
                                            value,
                                            label: <Tag>{sanitizeWords(value)}</Tag>
                                        })}
                                        label="Location Type"
                                        value={form.location_type}
                                        onChange={(val) => handleChange({ target: { name: "location_type", value: val.value } })}
                                        menuPlacement="bottom"
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                </>
                            )
                        })}
                    </div>
                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)} style={{ marginBottom: "2rem" }}>
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Personal Information",
                            body: (
                                <>
                                    <InputEmployeeFile
                                        keyName={EMPLOYEE_FILES.PHOTO.key}
                                        id={current?.id}
                                        style={{ marginTop: ".5rem" }}
                                        value={form.uploads[EMPLOYEE_FILES.PHOTO.key]}
                                        label="Add Photo"
                                        name="uploads.photo"
                                        isLoading={isGettingEmployee}
                                        onChange={(_, file) => handleChange({ target: { name: "uploads.photo", value: file } })}
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="Company ID"
                                        name="companyID"
                                        onChange={handleChange}
                                        value={form.companyID}
                                        isLoading={isGettingEmployee}
                                        minLength={1}
                                        maxLength={10}
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="First Name"
                                        name="first_name"
                                        onChange={handleChange}
                                        value={sanitizeWords(form.first_name)}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="Last Name"
                                        name="last_name"
                                        onChange={handleChange}
                                        value={sanitizeWords(form.last_name)}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <SelectConstant
                                        base={GENDER}
                                        label="Gender"
                                        name="gender"
                                        onChange={(val) => handleChange({ target: { name: "gender", value: val.value } })}
                                        value={form?.gender}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <SelectNationality
                                        label="Nationality"
                                        onChange={(val) => handleChange({ target: { name: "nationality", value: val.value } })}
                                        value={form?.nationality}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        label="Birthdate"
                                        name="birthdate"
                                        onChange={(value) => handleChange({ target: { name: "birthdate", value } })}
                                        selected={form?.birthdate && new Date(form?.birthdate)}
                                        timezone={timezone}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <SelectConstant
                                        base={MARITAL_STATUS}
                                        label="Marital Status"
                                        name="marital_status"
                                        onChange={(val) => handleChange({ target: { name: "marital_status", value: val.value } })}
                                        value={form.marital_status}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.MOBILE}
                                        label="Mobile Number"
                                        name="mobile_number"
                                        style={{ minWidth: "8rem" }}
                                        onChange={handleChange}
                                        value={form.mobile_number}
                                        minLength={8}
                                        isLoading={isGettingEmployee}
                                        isCodeRightPos
                                    />
                                    <Input
                                        type={INPUT_TYPE.EMAIL}
                                        label="Email Address"
                                        name="email_address"
                                        value={form.email_address}
                                        onChange={handleChange}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="Home Address"
                                        name="home_address"
                                        value={form.home_address}
                                        onChange={handleChange}
                                        isLoading={isGettingEmployee}
                                    />
                                </>
                            )
                        })}
                        <UpdateFilesInputs
                            base={BASE_CLASS_UPDATE_MODAL}
                            form={form}
                            current={current}
                            isGettingEmployee={isGettingEmployee}
                            onChange={handleChange}
                        />
                    </div>
                    <Divider />
                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)} style={{ marginBottom: "2rem" }}>
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Work Details",
                            body: (
                                <>
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="Grade"
                                        name="grade"
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value.toLowerCase() } })}
                                        value={(form.grade && form?.grade?.toUpperCase()) || ""}
                                        maxLength={2}
                                        isLoading={isGettingEmployee}
                                        subtext={{
                                            message: <span className="fade">Max of 2 characters is allowed</span>
                                        }}
                                    />
                                    <EmployeeSelectLazy
                                        label="Direct Supervisor"
                                        value={form[PERSONAL_FIELDS.DIRECT_SUPERVISOR]}
                                        onChange={(target) =>
                                            handleChange({
                                                target: { name: PERSONAL_FIELDS.DIRECT_SUPERVISOR, value: target || null }
                                            })
                                        }
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        excludeIds={[id]}
                                        isClearable
                                        allowOnShift
                                        isOutlined
                                        disabledOutline
                                    />
                                    <EmployeeSelectLazy
                                        label="Direct Manager"
                                        value={form[PERSONAL_FIELDS.DIRECT_MANAGER]}
                                        onChange={(target) =>
                                            handleChange({
                                                target: { name: PERSONAL_FIELDS.DIRECT_MANAGER, value: target || null }
                                            })
                                        }
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        excludeIds={[id]}
                                        isClearable
                                        allowOnShift
                                        isOutlined
                                        disabledOutline
                                    />
                                    <DepartmentsSelectLazy
                                        label="Department"
                                        name="CompanyDepartment"
                                        onChange={(val) => handleChange({ target: { name: "CompanyDepartment", value: val } })}
                                        value={form.CompanyDepartment}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                        required
                                    />
                                    <DesignationsSelectLazy
                                        label="Designation"
                                        name="CompanyDesignation"
                                        onChange={(val) => handleChange({ target: { name: "CompanyDesignation", value: val } })}
                                        value={form.CompanyDesignation}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                        required
                                    />
                                    <SiteSelectLazy
                                        label="Work Sites"
                                        name="CompanySites"
                                        onChange={(val) => handleChange({ target: { name: "CompanySites", value: val } })}
                                        value={form.CompanySites}
                                        isReadableSelected={!isShiftEditable}
                                        isClearable={isShiftEditable}
                                        isSearchable={false}
                                        isLoading={isGettingEmployee}
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                    />
                                    {!isShiftEditable && (
                                        <span className="small-font danger-color flex center bold" style={{ marginTop: "1rem" }}>
                                            Note: The folllowing fields are disabled because employee is currently on shift.
                                        </span>
                                    )}
                                    <SelectConstant
                                        base={SCHEDULE_TYPE}
                                        label="Schedule"
                                        value={form.schedule_type}
                                        onChange={(val) => handleChange({ target: { name: "schedule_type", value: val.value } })}
                                        isClearable={false}
                                        isDisabled={!isShiftEditable}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <WorkShiftSelectLazy
                                        label="Work Shift"
                                        name="EmployeeWorkShift"
                                        onChange={(val) => handleChange({ target: { name: "EmployeeWorkShift", value: val } })}
                                        value={form.EmployeeWorkShift}
                                        timezone={timezone}
                                        isDisabled={!isShiftEditable}
                                        isSearchable={false}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        isClearable
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="No. of Weekly Off Days"
                                        name="number_weekly_off_days"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.number_weekly_off_days}
                                        afterExtra={<span>Day(s)</span>}
                                        constraint={{ min: 1, max: 6 }}
                                        disabled={!isShiftEditable}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <SelectConstant
                                        base={DAY_ABBREVIATION}
                                        label="Off Days"
                                        name="off_days"
                                        value={form?.off_days || []}
                                        onChange={(od) => {
                                            const wod = form.number_weekly_off_days;
                                            const curr = od.map((o) => o.value);
                                            const len = curr.length;
                                            const withinBounds = len <= wod;
                                            handleChange({
                                                target: {
                                                    name: "off_days",
                                                    value: withinBounds ? curr : curr.slice(od.length - form.number_weekly_off_days)
                                                }
                                            });
                                        }}
                                        isDisabled={!isShiftEditable}
                                        isLoading={isGettingEmployee}
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                </>
                            )
                        })}
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Bank Details",
                            body: (
                                <>
                                    <SelectBank
                                        name="EmployeeBankDetail.short_name"
                                        label="Bank short name"
                                        value={form.EmployeeBankDetail?.short_name}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeBankDetail.short_name", value: val.value } })}
                                        isLoading={isGettingEmployee}
                                        minLength={2}
                                        maxLength={10}
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="Bank Account No."
                                        name="EmployeeBankDetail.account_number"
                                        onChange={handleChange}
                                        value={form.EmployeeBankDetail?.account_number}
                                        isLoading={isGettingEmployee}
                                        minLength={8}
                                        maxLength={20}
                                    />
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        label="IBAN"
                                        name="EmployeeBankDetail.iban"
                                        onChange={handleChange}
                                        value={form.EmployeeBankDetail?.iban}
                                        isLoading={isGettingEmployee}
                                        minLength={15}
                                        maxLength={20}
                                    />
                                </>
                            )
                        })}
                    </div>
                    <Divider />
                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)}>
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Contract Details",
                            body: (
                                <>
                                    <SelectConstant
                                        base={CONTRACT_STATUS}
                                        label="Contract Status"
                                        value={form?.EmployeeContract?.status}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.status", value: val.value } })}
                                        isClearable={false}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <SelectConstant
                                        base={CONTRACT_TYPE}
                                        label="Contract Type"
                                        value={form?.EmployeeContract?.contract_type}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.contract_type", value: val.value } })}
                                        isClearable={false}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <SelectConstant
                                        base={CONTRACT_TERM}
                                        label="Contract Term"
                                        value={form?.EmployeeContract?.contract_term}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.contract_term", value: val.value } })}
                                        isClearable={false}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        label="Hiring Date"
                                        name="EmployeeContract.hiring_date"
                                        onChange={(value) => handleChange({ target: { name: "EmployeeContract.hiring_date", value } })}
                                        selected={form?.EmployeeContract?.hiring_date && new Date(form?.EmployeeContract?.hiring_date)}
                                        timezone={timezone}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        label="Joining Date"
                                        name="EmployeeContract.joining_date"
                                        onChange={(value) => handleChange({ target: { name: "EmployeeContract.joining_date", value } })}
                                        selected={form?.EmployeeContract?.joining_date && new Date(form?.EmployeeContract?.joining_date)}
                                        timezone={timezone}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Period"
                                        name="EmployeeContract.period"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.period}
                                        afterExtra={<span>Month(s)</span>}
                                        isLoading={isGettingEmployee}
                                        constraint={{ min: 1 }}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Probation Period"
                                        name="EmployeeContract.probation_period"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.probation_period}
                                        afterExtra={<span>Month(s)</span>}
                                        isLoading={isGettingEmployee}
                                        constraint={{ min: 1 }}
                                        required
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Basic Salary"
                                        name="EmployeeContract.basic_amount"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.basic_amount}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                        required
                                    />
                                    <SelectConstant
                                        base={SALARY_FREQUENCY}
                                        label="Salary Fequency"
                                        value={form.EmployeeContract?.salary_frequency}
                                        onChange={(val) => handleChange({ target: { name: "EmployeeContract.salary_frequency", value: val.value } })}
                                        isClearable={false}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                    <SelectConstant
                                        base={PAYMENT_MODE}
                                        label="Salary Payment Mode"
                                        name="salary_payment_mode"
                                        onChange={(val) => handleChange({ target: { name: "salary_payment_mode", value: val.value } })}
                                        value={form.salary_payment_mode}
                                        isLoading={isGettingEmployee}
                                        isOutlined
                                        disabledOutline
                                        required
                                    />
                                </>
                            )
                        })}
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "Allowance",
                            body: (
                                <>
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Accomodation"
                                        name="EmployeeContract.allowance.accomodation"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.accomodation}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Food Allowance"
                                        name="EmployeeContract.allowance.food"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.food}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Transportation Allowance"
                                        name="EmployeeContract.allowance.transportation"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.transportation}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Communication Allowance"
                                        name="EmployeeContract.allowance.communication"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.communication}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Education Allowance"
                                        name="EmployeeContract.allowance.education"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.education}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        label="Other Allowance"
                                        name="EmployeeContract.allowance.other"
                                        onChange={(e) =>
                                            handleChange({ target: { name: e.target.name, value: e.target.value ? Number(e.target.value) : 0 } })
                                        }
                                        value={form?.EmployeeContract?.allowance?.other}
                                        afterExtra={renderCurrencyExtra}
                                        isLoading={isGettingEmployee}
                                    />
                                </>
                            )
                        })}
                    </div>
                    <div className={createClass("__inner-row", BASE_CLASS_UPDATE_MODAL)}>
                        {createGroup({
                            base: BASE_CLASS_UPDATE_MODAL,
                            title: "",
                            body: (
                                <>
                                    <Input
                                        type={INPUT_TYPE.TEXTAREA}
                                        label="Job Description"
                                        name="EmployeeContract.job_description"
                                        placeholder="Your job description here..."
                                        onChange={(e) => handleChange({ target: { name: e.target.name, value: e.target.value.trim() } })}
                                        value={form?.EmployeeContract?.job_description || ""}
                                        parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                        isLoading={isGettingEmployee}
                                    />
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    id: PropTypes.any,
    photo: PropTypes.string,
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func
};

export default UpdateModal;
